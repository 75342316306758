/**
 * Recent Searches | Search History
 * Contains main functionality for "Recent Searches" (aka user search history).
 * Is only visible when the input for search has focus, is empty (no text), and at least one
 * entry / previously saved search exists. The only exception is if the user presses "escape".
 * Only then the search field will be empty, with focus, and NOT display Recent Searches.
 *
 * NOT AVAILABLE to 3rd party sites as window.localStorage cannot cross domains (as coded).
 */
import { getDomElems } from '../../../../_shared-components/getDomElems';
import { code, getKeyCode, keyCode } from '../../../../_shared-components/keyCode';
import LISTBOX, { SEARCH_HISTORY } from './constants';
import * as listbox from './listboxUtil';
import getScriptData from '../../util/getScriptData';
import handleClickAnalyticsForSearch from './searchMetrics';

const recentSearchState = {
  maxCapacity: 10,
  recentSearches: [],
};
/* Resets state on init */
const setStateOnInit = () => {
  recentSearchState.maxCapacity = 10;
  recentSearchState.recentSearches = [];
  recentSearchState.recentHistoryBox = {};
};
let lastClickedElement = null;
/**
 * Retrieves the recentSearches from localStorage stored under the key 'savedSearches'
 * @param {Window} win
 */
const getRecentSearches = (win = window) => (
  JSON.parse(win.localStorage.getItem(SEARCH_HISTORY.LOCAL_STORAGE_KEY)) || []
);

/**
 * Removes the savedSearches localStorage key and data
 * @param {Window} win - global window
 */
const clearSearchHistory = (win = window) => {
  win.localStorage.removeItem(SEARCH_HISTORY.LOCAL_STORAGE_KEY);
};

/**
 * Sets search history to local storage.
 * @param {object[]} searchHistory - array of objects with a single property: "searchTerm"
 * @param win
 */
const setRecentSearches = (searchHistory, win = window) => {
  win.localStorage.setItem(SEARCH_HISTORY.LOCAL_STORAGE_KEY, JSON.stringify(searchHistory));
};

/**
 * Calls getRecentSearches to see if there are any recentSearches, checks to see if the current
 * searchTerm is empty, removes a recentSearch if there is are more than ten and then sets the
 * current searchTerm
 * @param {string} searchTerm
 * @param {Window} win
 */
const addToSearchHistory = (searchTerm = '', win = window) => {
  const { maxCapacity } = recentSearchState;
  if (searchTerm === '') {
    return;
  }
  const recentSearches = getRecentSearches(win)
    .filter((search) => search.searchTerm.toLowerCase() !== searchTerm.toLowerCase());

  if (recentSearches.length >= maxCapacity) {
    recentSearches.pop();
  }
  setRecentSearches([{ searchTerm }, ...recentSearches]);
};

/**
 * Checks if element is the clear search history button by id
 * @param {HTMLLIElement} elemToCheck
 * @returns {boolean} - true if element is the clear search history button
 */
const isDeleteSearchHistoryButton = (elemToCheck) => (
  elemToCheck && elemToCheck.firstElementChild
  && elemToCheck.firstElementChild.id === SEARCH_HISTORY.DELETE.ID
);

/**
 * @param {number} listEntryCount Current count clickable list items in recent searches.
 *  Number will always be greater than one because the delete history button is included.
 * @returns {string} describing the current number of Recent Search History entries, including
 * directions on how to navigation the open listbox.
 */
const getAriaLiveTextForTotalResults = (listEntryCount) => (
  `Recent Search History has ${listEntryCount} options available. ${LISTBOX.ARIA_LIVE.DIRECTIONS}`
);

const getAllListEntries = () => (
  recentSearchState.recentHistoryBox.querySelectorAll(LISTBOX.ALL_LI.SELECTOR)
);

/**
 * Creates List Entry with a child element whose tagName and properties are
 * specified in the params.  Used to build the title and delete button list entries.
 * @param {string} childTagName: string tagName. Ex. 'button', 'div', 'span'.
 * @param {object} elemPropsToSet: properties to set for the child element
 * @returns {HTMLLIElement}
 */
const createListEntryWithChildElem = (childTagName, elemPropsToSet) => {
  const listEntry = document.createElement('li');
  listEntry.className = 'search-history__entry';
  const elem = document.createElement(childTagName);
  Object.keys(elemPropsToSet).forEach((key) => {
    elem[key] = elemPropsToSet[key];
  });
  listEntry.appendChild(elem);
  return listEntry;
};

const onMouseLeave = (e) => {
  const { target } = e;
  const { ariaLiveRegion, searchField } = recentSearchState;
  const listEntries = getAllListEntries();
  if (target.classList.contains(LISTBOX.CSS.VISUAL_FOCUS)) {
    listbox.resetListbox(searchField, listEntries);
    ariaLiveRegion.textContent = getAriaLiveTextForTotalResults(listEntries.length);
  }
};

const onMouseEnter = (e) => {
  const { target } = e;
  const { ariaLiveRegion, searchField } = recentSearchState;
  const listEntries = getAllListEntries();
  listbox.setVisualFocus(searchField, target, listEntries);
  ariaLiveRegion.textContent = '';
};

/**
 * Creates, adds mouseEventListeners, and appends markup to the DOM with the Search History list.
 * First entry is read-only title (unclickable, unfocusable). The last entry is a delete button
 * so user can clear their recent search history.
 * @param {HTMLDivElement} recentHistoryBox listbox and parent element of ul
 * @param {object[]} results: Array of previously saved searches
 */
const appendRecentSearches = (recentHistoryBox, results = []) => {
  const ul = recentHistoryBox.querySelector(`#${SEARCH_HISTORY.UL_ID}`);
  ul.innerHTML = '';
  /* First entry of recent searches results is title */
  ul.appendChild(createListEntryWithChildElem('div', {
    className: SEARCH_HISTORY.TITLE.CSS,
    textContent: SEARCH_HISTORY.TITLE.TEXT,
  }));

  [...results].forEach(({ searchTerm }, index) => {
    const li = document.createElement('li');
    li.className = LISTBOX.CSS.LIST_ITEM;
    li.textContent = searchTerm;
    li.setAttribute('aria-label', searchTerm);
    li.setAttribute('role', 'option');
    /* id with index used for building metrics.link config */
    li.setAttribute('id', `${SEARCH_HISTORY.LI_ID(index)}`);
    /* Add MouseEnter, MouseLeave listeners */
    listbox.addMouseMoveEventListeners(li, onMouseEnter, onMouseLeave);
    ul.appendChild(li);
  });
  /* Last entry of recent searches is clear search history button */
  const clearHistoryListItem = createListEntryWithChildElem('button', {
    className: SEARCH_HISTORY.DELETE.CSS,
    id: SEARCH_HISTORY.DELETE.ID,
    textContent: SEARCH_HISTORY.DELETE.TEXT,
    type: 'button',
    tabIndex: '-1',
  });
  clearHistoryListItem.setAttribute('role', 'option');
  clearHistoryListItem.setAttribute('id', `${SEARCH_HISTORY.LI_ID(results.length)}`);
  clearHistoryListItem.setAttribute('aria-label', `${SEARCH_HISTORY.DELETE.TEXT}, button`);
  /* add listener to button (not li) */
  listbox.addMouseMoveEventListeners(clearHistoryListItem, onMouseEnter, onMouseLeave);
  ul.appendChild(clearHistoryListItem);
};

/**
 * Controls visibility / expand-collapse of recentSearchBox for both recent searches and
 * filtered results (aka autocomplete).
 * @param {HTMLDivElement} recentHistoryBox - element that contains the list entries of recent
 *        searches or filtered results
 * @param {HTMLInputElement} searchField - input element of search field
 * @param {boolean} isVisible - boolean used to determine state of visibility / expansion
 */
const setRecentSearchBoxVisibility = (
  recentHistoryBox,
  searchField,
  isVisible = false,
) => {
  const searchTerm = searchField.value.trim();
  if (!isVisible || searchTerm.length > 0) {
    listbox.resetListbox(searchField, [...getAllListEntries()]);
    recentHistoryBox.setAttribute('data-visible', 'hidden');
  }
  if (searchTerm.length === 0) {
    searchField.parentElement.setAttribute('aria-expanded', `${isVisible}`);
    recentHistoryBox.setAttribute('data-visible', isVisible ? 'visible' : 'hidden');
  }
};

/**
 * Resets search history state, visibility, and focus when user deletes all recent searches
 * @param {HTMLDivElement} recentHistoryBox
 * @param {HTMLInputElement} searchField
 */
const deleteAllRecentSearches = (recentHistoryBox, searchField) => {
  const { ariaLiveRegion } = recentSearchState;
  clearSearchHistory();
  recentSearchState.recentSearches = [];
  ariaLiveRegion.textContent = '';
  setRecentSearchBoxVisibility(recentHistoryBox, searchField);
  searchField.focus();
};

/**
 * Handles the keyboard logic for list entries in RecentSearchBox
 * @param e
 */
const handleKeyPress = (e) => {
  const {
    ariaLiveRegion,
    recentHistoryBox,
    searchField,
  } = recentSearchState;
  /* limit results to current list */
  const listEntries = recentHistoryBox.querySelectorAll(LISTBOX.ALL_LI.SELECTOR);
  const currentIndex = listbox.getIndexFromElement(recentHistoryBox.querySelector(`.${LISTBOX.CSS.VISUAL_FOCUS}`));
  const keyCodeVal = getKeyCode(e);
  switch (keyCodeVal) {
    case code.ARROW_DOWN:
    case keyCode.ARROW_DOWN: {
      const updatedIndex = listbox.getUpdatedIndexOnArrowDown(currentIndex, listEntries.length);
      ariaLiveRegion.textContent = '';
      listbox.setVisualFocus(searchField, listEntries[updatedIndex], listEntries);
      localStorage.setItem(
        SEARCH_HISTORY.RECENTLY_SELECTED,
        listEntries[updatedIndex]?.innerText.trim()
      );
      break;
    }
    case code.ARROW_UP:
    case keyCode.ARROW_UP: {
      const updatedIndex = listbox.getUpdatedIndexOnArrowUp(currentIndex, listEntries.length);
      listbox.setVisualFocus(searchField, listEntries[updatedIndex], listEntries);
      ariaLiveRegion.textContent = '';
      localStorage.setItem(
        SEARCH_HISTORY.RECENTLY_SELECTED,
        listEntries[updatedIndex]?.innerText.trim()
      );
      break;
    }
    case code.ESCAPE:
    case keyCode.ESCAPE:
      searchField.focus();
      /* close search history. Only case where searchField has focus and does
       * NOT display search history */
      setRecentSearchBoxVisibility(recentHistoryBox, searchField);
      ariaLiveRegion.textContent = '';
      break;
    case code.END:
    case keyCode.END:
    case code.HOME:
    case keyCode.HOME:
      searchField.focus();
      listbox.resetListbox(searchField, listEntries);
      ariaLiveRegion.textContent = getAriaLiveTextForTotalResults(listEntries.length);
      break;
    case code.ARROW_LEFT:
    case keyCode.ARROW_LEFT:
    case code.ARROW_RIGHT:
    case keyCode.ARROW_RIGHT:
      listbox.resetListbox(searchField, listEntries);
      ariaLiveRegion.textContent = getAriaLiveTextForTotalResults(listEntries.length);
      break;
    default:
      break;
  }
};

const onKeyUp = (recentHistoryBox, e) => {
  const { recentSearches, ariaLiveRegion, searchForm } = recentSearchState;
  const { target: searchField } = e;
  const searchTerm = searchField.value.trim();
  const isVisible = (recentSearches && recentSearches.length > 0 && searchTerm.length === 0);
  const visibilityChanged = listbox.shouldUpdateVisibility(recentHistoryBox, isVisible);
  if (visibilityChanged) {
    setRecentSearchBoxVisibility(recentHistoryBox, searchField, isVisible);
    /* If autosuggest is not enabled then set aria-expanded & clear aria-live */
    if (!isVisible && searchForm.querySelectorAll('[role="listbox"]').length === 1) {
      searchField.parentElement.setAttribute('aria-expanded', 'false');
      ariaLiveRegion.textContent = '';
    }
  }
  if (isVisible) {
    /* update DOM only when necessary */
    if (visibilityChanged) {
      appendRecentSearches(recentHistoryBox, recentSearches);
      /* increment length to include delete history button */
      ariaLiveRegion.textContent = getAriaLiveTextForTotalResults(recentSearches.length + 1);
    }
    handleKeyPress(e);
  }
};

const onBlur = (ariaLiveRegion, recentHistoryBox, e) => {
  const { target: searchField } = e;
  const ariaLive = ariaLiveRegion;
  // search box recommendations AB test June 2024
  // onBlur gets called when the slider does not yet have focus
  const searchRecsSlider = document.getElementById('search-recommendations');
  const sliderContainsLastClickedElement = searchRecsSlider
    ? searchRecsSlider.contains(lastClickedElement) : false;
  if (sliderContainsLastClickedElement) {
    setRecentSearchBoxVisibility(recentHistoryBox, searchField, true);
  } else {
    setRecentSearchBoxVisibility(recentHistoryBox, searchField);
  }
  ariaLive.textContent = '';
};

const onMouseDown = (searchField, searchForm, recentHistoryBox, event) => {
  // search box recommendations AB test June 2024
  lastClickedElement = event.target || document.activeElement;
  const { target: mouseTrgt } = event;
  /* If LI contains BUTTON - event registers on BUTTON */
  const target = mouseTrgt.tagName === 'BUTTON' ? mouseTrgt.parentElement : mouseTrgt;
  const { msMatchesSelector, id = '' } = target;
  if (!target.matches) {
    /* IE 11 support */
    target.matches = msMatchesSelector;
  }
  // search recently viewed test July 2024
  // mousedown gets called when selecting a tile when the slider previously had focus
  const searchRecsSlider = document.getElementById('search-recommendations');
  if (searchRecsSlider?.contains(lastClickedElement)) {
    setRecentSearchBoxVisibility(recentHistoryBox, searchField, true);
  }
  if (target && target.matches(`${LISTBOX.ALL_LI.SELECTOR}`)) {
    const resultEntry = target;
    const value = (resultEntry.innerText).trim();
    if (isDeleteSearchHistoryButton(target)) {
      deleteAllRecentSearches(recentHistoryBox, searchField);
      handleClickAnalyticsForSearch(resultEntry);
    } else if (id.startsWith('autosuggest')) {
      /* Entry in autosuggest was selected */
      addToSearchHistory(value);
    } else {
      /* Entry in search history was selected */
      listbox.setSearchField(searchField, value);
      addToSearchHistory(value);
      handleClickAnalyticsForSearch(resultEntry);
      searchForm.submit();
    }
  }
};

const onFocus = (recentHistoryBox, e) => {
  const { target: searchField } = e;
  const searchTerm = searchField.value.trim();
  const { recentSearches = [], ariaLiveRegion } = recentSearchState;
  if (recentSearches && recentSearches.length > 0 && searchTerm.length === 0) {
    setRecentSearchBoxVisibility(recentHistoryBox, searchField, true);
    appendRecentSearches(recentHistoryBox, recentSearches);
    /* increment recentSearches length to include delete history button */
    ariaLiveRegion.textContent = getAriaLiveTextForTotalResults(recentSearches.length + 1);
  }
};

/**
 * Submitter is always search button. Invoked when selecting list entries via keyboard events
 * or when text is submitted from the input element via keyboard or clicking search button
 * @param recentHistoryBox
 * @param searchField
 * @param e
 */
const onFormSubmit = (recentHistoryBox, searchField, e) => {
  const { target: searchForm } = e;
  const liWithVisualFocus = searchForm.querySelector(`.${LISTBOX.CSS.VISUAL_FOCUS}`);
  if (isDeleteSearchHistoryButton(liWithVisualFocus)) {
    e.preventDefault();
    deleteAllRecentSearches(recentHistoryBox, searchField);
  } else {
    if (liWithVisualFocus) {
      listbox.setSearchField(searchField, liWithVisualFocus.innerText.trim());
      handleClickAnalyticsForSearch(liWithVisualFocus);
    }
    /* Saves text submitted from input and maintains list order if entry in history was selected */
    addToSearchHistory(searchField.value.trim());
  }
};

/**
 * Helper method that retrieves the configured property for number of saved recent search entries.
 * Range of the configured value (x) is 0 < x < 26.
 * capacity cannot be set to null, 0, or > 25 entries via configuration.
 * @returns {number} Configured number within the range of . Has default value of 10
 */
const getCustomMaxCapacityOrDefault = () => {
  const { recentSearchMaxSize = 10 } = getScriptData('gnav-data') || {};
  const useFallback = recentSearchMaxSize === null
    || (recentSearchMaxSize * 1) < 1
    || (recentSearchMaxSize * 1) > 25;
  return useFallback ? 10 : (recentSearchMaxSize * 1);
};

/* init */
const init = (selectors) => {
  const searchElems = getDomElems(selectors);
  if (searchElems === null) return;
  setStateOnInit();
  /* build state */
  let recentSearches = getRecentSearches();
  const maxCapacity = getCustomMaxCapacityOrDefault();
  /* Handles edge case if the capacity is lowered, but user has previous history */
  if (recentSearches.length > maxCapacity) {
    setRecentSearches(recentSearches.slice(0, maxCapacity));
    recentSearches = getRecentSearches();
  }
  /* find and set up related elems */
  const { searchForm, recentHistoryBox } = searchElems;
  const searchField = searchForm.querySelector('[data-js=search-field]');
  searchField.setAttribute('aria-activedescendant', '');
  const ariaLiveRegion = searchForm.querySelector(`${LISTBOX.ARIA_LIVE.SELECTOR}`);
  Object.assign(recentSearchState, searchElems, {
    ariaLiveRegion, recentSearches, searchField, maxCapacity,
  });
  /* Event Listeners */
  searchField.addEventListener('focus', onFocus.bind(null, recentHistoryBox));
  searchField.addEventListener('blur', onBlur.bind(null, ariaLiveRegion, recentHistoryBox));
  searchField.addEventListener('keydown', listbox.preventDefaultOnKeyDown);
  searchField.addEventListener('keyup', onKeyUp.bind(null, recentHistoryBox));
  searchForm.addEventListener('submit', onFormSubmit.bind(null, recentHistoryBox, searchField));
  /* mousedown has slightly larger scope in order to capture selections made from autosuggest */
  searchForm.addEventListener('mousedown', onMouseDown.bind(null, searchField, searchForm, recentHistoryBox));
};
export default init;
